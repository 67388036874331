var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"study"},[_c('layout-header-sp'),_c('main',{staticClass:"study__main"},[(_vm.isActive.loadMarkdown && _vm.isActive.loadConfig)?_c('section',{staticClass:"study__main__left"},[_c('div',{staticClass:"study__main__left__chapter"},[_c('div',{staticClass:"study__main__left__chapter__config"},[_c('p',[_vm._v(_vm._s(_vm.setLessonTitle))]),_c('h3',[_vm._v(_vm._s(_vm.setChapterTitle))])]),_c('div',{staticClass:"study__main__left__chapter__markdown"},[_c('p',{staticClass:"mdbase",domProps:{"innerHTML":_vm._s(_vm.parseMarkdown)}})]),_c('div',{staticClass:"study__main__left__chapter__func"},[_c('button-check-box',{attrs:{"isActive":_vm.isActive.progressCheckBox,"text":"チャプターの内容を理解しました"},on:{"emit":function($event){return _vm.changeProgress()}}}),_c('div',{staticClass:"study__main__left__chapter__func__move"},[(_vm.showPrevChapterButton)?_c('button-normal',{attrs:{"white":""}},[_c('router-link',{attrs:{"to":{
                name: "SpStudy",
                params: _vm.setPrevChapterParam
              }}},[_vm._v(" 前のチャプターに戻る ")])],1):_vm._e(),(_vm.showNextChapterButton)?_c('button-normal',[_c('router-link',{attrs:{"to":{
                name: "SpStudy",
                params: _vm.setNextChapterParam
              }}},[_vm._v(" 次のチャプターに進む ")])],1):_vm._e()],1),_c('button-normal',[_c('router-link',{attrs:{"to":{
              name: "SpDashboard" }}},[_vm._v(" トップページに戻る ")])],1)],1),_c('div',{staticClass:"study__main__left__chapter__tweet"},[_c('button-twitter-share',{attrs:{"buttonTitle":"Twitterで学習進捗をシェア","tweetText":_vm.createTweetText}})],1)])]):_vm._e()]),_c('layout-footer-sp')],1)}
var staticRenderFns = []

export { render, staticRenderFns }